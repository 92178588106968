import React from 'react';
import Banner from '../components/Banner';
import Categories from '../components/Categories';
import LatestProducts from '../components/LatestProducts';

function Home() {
  return (
    <>
      <Banner />
      <Categories />
      <LatestProducts />
    </>
  );
}

export default Home;
