import React from 'react';
import { Carousel, Container } from 'react-bootstrap';

function Home() {
  return (
    <Container>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/6371b684fec76d85.jpg?q=20"
            alt="First slide"
          />
          </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/e975420770a1fc46.jpg?q=20"
            alt="Second slide"
          />
         
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/e975420770a1fc46.jpg?q=20"
            alt="Third slide"
          />
         </Carousel.Item>
      </Carousel>
    </Container>
  );
}

export default Home;
