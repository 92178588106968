import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Container, Row, Col } from 'react-bootstrap';
//import config from './config';  // Import config file


const DealsOfTheDay = () => {
    const [deals, setDeals] = useState([]);

    useEffect(() => {
        const fetchDeals = async () => {
            try {
                const response = await axios.get('https://affiliate-api.flipkart.net/affiliate/offers/v1/all/json', {
                    headers: {
                        'Fk-Affiliate-Id': 'dkmadhesi', // Using globally from config
                        'Fk-Affiliate-Token':'0fe2e77800e4421588c8d73293ea8757'
                    }
                });
                console.log(response.data);  // Inspect the API response structure here
                                setDeals(response.data.dotdList); // Assuming 'dotdList' contains the deals data
            } catch (error) {
                console.error('Error fetching deals:', error);
            }
        };

        fetchDeals();
    }, []);

    return (
        <Container>
            <Row>
                <h1>Deals of the day</h1>
                {deals.map((deal) => (
                    <Col key={deal.url} md={4}>
                        <Card className="mb-4">
                            <Card.Img variant="top" src={deal.imageUrls[0].url} />
                            <Card.Body>
                                <Card.Title>{deal.title}</Card.Title>
                                <Card.Text>{deal.description}</Card.Text>
                                <Card.Link href={deal.url} target="_blank">View Offer</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default DealsOfTheDay;
