import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';

function ProductDetail() {
  const { id } = useParams();
  const product = { id, title: 'Product 1', price: '$100', description: 'Product description here' };

  return (
    <Container>
      <Row>
        <Col md={6}>
          <img src="https://via.placeholder.com/500" alt={product.title} />
        </Col>
        <Col md={6}>
          <h2>{product.title}</h2>
          <p>{product.description}</p>
          <h3>{product.price}</h3>
          <a href={`https://www.flipkart.com/product/${id}`} target="_blank" rel="noopener noreferrer">
            Buy Now
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default ProductDetail;
