import React from 'react';
import { Card } from 'react-bootstrap';
import { Container, Row, Col, Form } from 'react-bootstrap';

function ProductPage() {
  const products = [
    { id: 1, title: 'Product 1', price: '$100', category: 'Electronics' },
    { id: 2, title: 'Product 2', price: '$200', category: 'Fashion' },
  ];

  const handleFilter = (category) => {
    // Add filter logic
  };

  const handleSort = (sort) => {
    // Add sorting logic
  };

  return (
    <Container>
      <Row className="my-4">
        <Col md={3}>
          <h5>Filter by Category</h5>
          <Form.Select onChange={(e) => handleFilter(e.target.value)}>
            <option value="all">All Categories</option>
            <option value="electronics">Electronics</option>
            <option value="fashion">Fashion</option>
          </Form.Select>
        </Col>
        <Col md={3}>
          <h5>Sort by Price</h5>
          <Form.Select onChange={(e) => handleSort(e.target.value)}>
            <option value="low-to-high">Low to High</option>
            <option value="high-to-low">High to Low</option>
          </Form.Select>
        </Col>
      </Row>
      <Row>
        {products.map(product => (
          <Col key={product.id} md={4}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>{product.title}</Card.Title>
                <Card.Text>Price: {product.price}</Card.Text>
                <Card.Link href={`/products/${product.id}`}>View Product</Card.Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ProductPage;
