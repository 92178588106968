import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function Categories() {
  const categories = ['Electronics', 'Fashion', 'Home Appliances', 'Books'];

  return (
    <Container>
      <h2 className="my-4">Product Categories</h2>
      <Row>
        {categories.map((category, idx) => (
          <Col key={idx} md={3}>
            <Card className="mb-4">
              <Card.Img variant="top" src={`https://via.placeholder.com/300x200?text=${category}`} />
              <Card.Body>
                <Card.Title>{category}</Card.Title>
                <Card.Text>
                  Explore the best products in {category}.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Categories;
