import React from 'react';

function PrivacyPolicy() {
  return (
    <>
    <h1>PrivacyPolicy</h1>
    </>
  );
}

export default PrivacyPolicy;
