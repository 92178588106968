import React from 'react';

function Offers() {
  return (
    <>
    <h1>Offers</h1>
    </>
  );
}

export default Offers;
