import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function LatestProducts() {
  const products = [
    { id: 1, title: 'Product 1', price: '$100', img: 'https://via.placeholder.com/150' },
    { id: 2, title: 'Product 2', price: '$200', img: 'https://via.placeholder.com/150' },
  ];

  return (
    <Container>
      <h2 className="my-4">Latest Products</h2>
      <Row>
        {products.map(product => (
          <Col key={product.id} md={3}>
            <Card className="mb-4">
              <Card.Img variant="top" src={product.img} />
              <Card.Body>
                <Card.Title>{product.title}</Card.Title>
                <Card.Text>Price: {product.price}</Card.Text>
                <Card.Link href={`/products/${product.id}`}>View Product</Card.Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default LatestProducts;
